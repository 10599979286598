export type BrowserType = 'opera' | 'firefox' | 'safari' | 'ie' | 'edge' | 'chrome' | 'edge-chromium';
export type LogType = 'info' | 'success' | 'warning' | 'error';

export interface IMetaData {
  title?: string;
  description?: string;
  keywords?: string;
  ogTitle?: string;
  ogUrl?: string;
  ogImage?: string;
  ogImageAlt?: string;
  ogType?: string;
  ogDescription?: string;
  ogLocale?: string;
  ogSiteName?: string;
  articlePublishedTime?: string;
  articleAuthor?: string;
  twitterSiteName?: string;
  twitterTitle?: string;
  twitterDescription?: string;
  twitterImage?: string;
}
