<div class="breadcrumb">
  <img class="logo" src="assets/images/weather/wow-logo.webp" alt="wow hungary logo" />
  <ng-container *ngFor="let breadcrumb of breadcrumbs; index as i">
    <span>|</span>
    <a
      [routerLink]="(breadcrumb.route ? breadcrumb.route : ['/']) | localize"
      [ngClass]="{ breadcrumb: true, 'last-breadcrumb': i === breadcrumbs.length - 1 }"
    >
      {{ breadcrumbs[i].name }}
    </a>
  </ng-container>
</div>
