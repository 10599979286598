<div class="weather-widget" *ngIf="weatherData$ | async as weatherData; else loading">
  {{ weatherData.currentSettlement }}
  <ng-container *ngIf="weatherData.weather; else loading">
    <span class="weather-temp">{{ weatherData.weather.celsius }} °C</span>
    <img class="icon" [src]="weatherData.weather.iconUrl" />
  </ng-container>
</div>

<ng-template #loading>
  <div>Töltés...</div>
</ng-template>
