<section class="listing" #mainWrapper>
  <div class="section-head">
    <h2>{{ title }}</h2>
    <p class="desc">{{ subtitle }}</p>
  </div>
  <div class="wrapper">
    <div class="left">
      <div class="search-box">
        <div class="filter-box-title">Filter</div>
        <div class="search-inner-box">
          <i class="icon icon-search-green"></i>
          <input class="text-input" type="text" placeholder="Keyword" />
          <button class="icon icon-arrow-right-green submit-search-btn"></button>
        </div>
      </div>
      <div class="filter-box">
        <div class="filter-box-section">
          <div class="filter-box-title">Filter</div>
          <button class="clear-all-filter">
            <i class="icon icon-close-red"></i>
            Clear all filter
          </button>
          <ng-container *ngFor="let elem of data.filters; let i = index">
            <div class="custom-checkbox-c">
              <input type="checkbox" id="check_a_{{ i }}" />
              <label for="check_a_{{ i }}">{{ elem.name }}</label>
            </div>
          </ng-container>
        </div>
        <div class="filter-box-section">
          <div class="filter-box-title">Destination</div>
          <button class="clear-all-filter">
            <i class="icon icon-close-red"></i>
            Clear all filter
          </button>
          <ng-container *ngFor="let elem of data.destionations; let i = index">
            <div class="custom-checkbox-c">
              <input type="checkbox" id="check_b_{{ i }}" />
              <label for="check_b_{{ i }}">{{ elem.name }}</label>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="right">
      <ng-container *ngFor="let result of data.results">
        <a class="result-link-elem">
          <div class="tag">{{ result.tag }}</div>
          <div class="name">{{ result.name }}</div>
        </a>
      </ng-container>
    </div>
  </div>
</section>
