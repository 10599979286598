/// <reference types="google.maps" />
import { Component, AfterViewInit, Input, ViewChild, ElementRef } from '@angular/core';
import { TrendencyUtilsService } from 'trendency/utils';

@Component({
  selector: 'app-map-full-width',
  templateUrl: './map-full-width.component.html',
  styleUrls: ['./map-full-width.component.scss'],
})
export class MapFullWidthComponent implements AfterViewInit {
  @ViewChild('gmap') gmapElement: ElementRef;
  @Input() lat = 46.879738;
  @Input() lng = 17.901213;
  @Input() mapUIDisabled = true;

  latLng: any;
  public map: google.maps.Map;
  public options: google.maps.MapOptions;

  constructor(private readonly utilsService: TrendencyUtilsService) {}

  ngAfterViewInit(): void {
    this.initGoogle();
  }

  initGoogle(): void {
    if (this.utilsService.isBrowser()) {
      this.latLng = { lat: +this.lat, lng: +this.lng };
      this.options = {
        disableDefaultUI: this.mapUIDisabled,
        zoom: 13,
        center: this.latLng,
      };
    }

    if (this.lat && this.lng) {
      this.initMap();
    }
  }

  private initMap(): void {
    if (this.utilsService.isBrowser()) {
      this.map = new google.maps.Map(this.gmapElement.nativeElement, this.options);

      /* const image = {
        url: '/assets/images/mapin.webp',
        // This marker is 20 pixels wide by 32 pixels high.
        size: new google.maps.Size(40, 48),
        // The origin for this image is (0, 0).
        origin: new google.maps.Point(0, 0),
        // The anchor for this image is the base of the flagpole at (0, 32).
        anchor: new google.maps.Point(20, 48)
      }; */

      /* const marker = new google.maps.Marker({
        map: this.map,
        position: latLng,
        icon: image,
      }); */
    }
  }
}
