<section>
  <div class="map-wrapper" *ngIf="lat && lng">
    <div #gmap class="map-container"></div>
  </div>
</section>

<!--
<iframe 
src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d48397.5866375114!2d19.04850384049762!3d47.5126188492644!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNDfCsDI5JzI3LjEiTiAxOcKwMDInNTYuNiJF!5e0!3m2!1shu!2shu!4v1592570685213!5m2!1shu!2shu" 
width="100%" height="630" frameborder="0" style="border:0;" 
allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
-->
