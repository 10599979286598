import { Component } from '@angular/core';

@Component({
  selector: 'app-listing',
  templateUrl: './listing.component.html',
  styleUrls: ['./listing.component.scss'],
})
export class ListingComponent {
  title = 'listing page';
  subtitle = 'listing page subtitle';

  data = {
    filters: [
      { name: 'Szálláshely', value: true },
      { name: 'Fürdő', value: true },
      { name: 'Vendéglátóhely', value: true },
      { name: 'Borászat', value: true },
      { name: 'Egyéb', value: true },
    ],
    destionations: [
      { name: 'Greater Budapest', value: true },
      { name: 'Balaton', value: true },
      { name: 'Debrecen and its surroundings', value: true },
      { name: 'Eger region', value: true },
      { name: 'Győr and Pannonhalma', value: true },
    ],
    results: [
      { name: 'Accent Hotels Bonvital Wellness & Gastro Hotel****superior', tag: 'Szálláshely' },
      { name: 'Andrea Villa Hévíz', tag: 'Szálláshely' },
      { name: 'Badacsonyi Céh Turisztikai Egyesület', tag: 'Szálláshely' },
      { name: 'Anna Grand Hotel', tag: 'Egyéb' },
      { name: 'Andrea Villa Hévíz', tag: 'Egyéb' },
      { name: 'Admirál Villa**** Panzió', tag: 'Egyéb' },
    ],
  };
}
