import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-breadcrumb-dynamic',
  templateUrl: './breadcrumb-dynamic.component.html',
  styleUrls: ['./breadcrumb-dynamic.component.scss'],
})
export class BreadcrumbDynamicComponent {
  @Input() breadcrumbs: any[];
}
