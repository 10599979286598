import {ElementRef, EventEmitter, Output, Directive, AfterViewInit, HostBinding, Input, ChangeDetectorRef} from '@angular/core';
import { TrendencyUtilsService } from '../../utils/services/utils.service';

@Directive({
    // tslint:disable-next-line: directive-selector
    selector: '[corporateImageLazyLoad]'
})
export class corporateImageLazyLoadDirective implements AfterViewInit {
  @Output() public intersect: EventEmitter<boolean> = new EventEmitter<boolean>(false);

  @Input() src: string;
  @Input() backgroundImageSrc: string;
  @HostBinding('attr.src') srcAttr = null;

  @Input()
  set trendImageLazyLoadPlaceholder(value) {
    this.srcAttr = value;
  }

  private placeholderImage = '/assets/images/image-loader.gif';

  constructor (
    private el: ElementRef,
    private utilsService: TrendencyUtilsService,
    private cd: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    if (this.utilsService.isBrowser()) {
      this.getBackgroundImage();
      this.loadPlaceholder();
      if (this.canLazyLoad()) {
        this.lazyLoadImage();
      } else {
        this.utilsService.log(
          'corporateImageLazyLoadDirective: Nem lehet lazy load-olni, mert a böngésző nem támogatja az IntersectionObserver-t!', 'warning');
        this.loadImage();
      }
    }
  }

  private canLazyLoad() {
    return typeof window !== 'undefined' && 'IntersectionObserver' in window;
  }

  private lazyLoadImage() {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) {
          this.intersect.emit(true);
          this.loadImage();
          observer.unobserve(this.el.nativeElement);
        }
      });
    });
    observer.observe(this.el.nativeElement);
  }

  private loadImage() {
    if (this.src) {
      this.srcAttr = this.src;
    } else if (this.backgroundImageSrc) {
      this.el.nativeElement.style.backgroundImage = this.backgroundImageSrc;
    }
  }

  private loadPlaceholder() {
    if (this.src) {
      this.srcAttr = this.placeholderImage;
    } else if (this.backgroundImageSrc) {
      this.el.nativeElement.style.backgroundImage = `url(${this.placeholderImage})`;
    }
    this.cd.detectChanges();
  }

  private getBackgroundImage() {
    if (this.el.nativeElement.style.backgroundImage) {
      this.backgroundImageSrc = this.el.nativeElement.style.backgroundImage;
      this.el.nativeElement.style.backgroundImage = null;
    }
  }
}
