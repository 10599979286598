import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-coloredbg-elemlist',
  templateUrl: './coloredbg-elemlist.component.html',
  styleUrls: ['./coloredbg-elemlist.component.scss'],
})
export class ColoredbgElemlistComponent {
  @Input() data: any = require('../../../../assets/mock_jsons/cards-d.json');
}
