<a class="detailed-card-top {{ respStyle }}" [routerLink]="data.routerLink">
  <div
    *ngIf="data.image"
    class="bg"
    [ngStyle]="{ 'background-image': 'url(' + data.image + ')' } | ssrEmptyString"
    corporateImageLazyLoad
  ></div>
  <div class="bottom" *ngIf="data.tag">
    <div class="tag" [style]="'background-color: #' + data?.color">{{ data.tag }}</div>
  </div>
  <!--    <button class="fav-button" (click)="favButtonAction()">-->
  <!--      <i class="icon icon-heart-empty-white" *ngIf="data.favorite == 'false'"></i>-->
  <!--      <i class="icon icon-heart-full-red" *ngIf="data.favorite == 'true'"></i>-->
  <!--    </button>-->
</a>
<div class="detailed-card-content-box">
  <a *ngIf="data.name" [routerLink]="data.routerLink" class="title">{{ data.name }}</a>
  <a *ngIf="data.website" class="link" href="{{ data.website }}" target="_blank">Weboldal <i class="icon icon-arrow-right-more-red"></i></a>
  <div class="content-title">{{ 'label.contact' | translate }}</div>
  <div *ngIf="data.contact" class="content" [innerHTML]="data.contact"></div>
</div>
<a class="detailed-card-button button button-secondary" [routerLink]="data.routerLink">{{ 'label.details' | translate }}</a>
