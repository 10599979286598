<section class="bredcrumb">
  <div class="wrapper">
    <div class="breadcrumb-content">
      <i class="icon icon-logo-notext"></i>
      <!---
      <span class="red">&nbsp;|&nbsp; WHAT TO DO</span>
      &nbsp;|&nbsp; LAND OF WATERS
      -->
      <span>&nbsp;<a [routerLink]="['/'] | localize">CSODÁS MAGYARORSZÁG</a></span>
    </div>
  </div>
</section>
