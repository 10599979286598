import { Component, OnInit, AfterViewInit, Input, HostListener, ViewChild, ElementRef } from '@angular/core';
import { TrendencyUtilsService } from 'trendency/utils';
declare let $: any;

@Component({
  selector: 'app-tiled-cards',
  templateUrl: './tiled-cards.component.html',
  styleUrls: ['./tiled-cards.component.scss'],
})
export class TiledCardsComponent implements AfterViewInit, OnInit {
  @Input() data: any = require('../../../../assets/mock_jsons/cards-d.json');
  @ViewChild('tiledCardsObjElement') tiledCardsObjElement: ElementRef;
  view = '';
  constructor(private readonly utilsService: TrendencyUtilsService) {}

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.checkView();
  }

  ngOnInit(): void {
    this.checkView();
  }

  ngAfterViewInit(): void {
    if (this.utilsService.isBrowser()) {
      this.initSlick();
    }
  }

  checkView(): void {
    if (window.innerWidth < 1024) {
      this.view = 'mobile';
    } else {
      this.view = 'desktop';
    }

    this.initSlick();
  }

  initSlick(): void {
    $(this.tiledCardsObjElement.nativeElement).slick({
      dots: true,
      autoplay: false,
      lazyLoad: 'ondemand',
      centerMode: true,
      slidesToShow: 2,
      speed: 200,
      autoplaySpeed: 3000,
      variableWidth: true,
      arrows: false,
      infinite: true,
      accessibility: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            dots: false,
            centerMode: false,
          },
        },
      ],
    });
  }
}
