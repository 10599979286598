<section class="coloredbg-elemlist">
  <div class="section-head margin-b">
    <h2>Itinerary trips</h2>
    <p class="desc">
      Experience extraordinary vistas, charming small towns, and hidden gems in your own way. We help you plan your own holiday with these
      curated trips across Hungary.
    </p>
  </div>

  <div class="row elem-wrapper">
    <ng-container *ngFor="let card of data.list">
      <div class="card-wrapper">
        <app-link-card [data]="card" [respStyle]="'style-c'"></app-link-card>
      </div>
    </ng-container>
    <div class="bottom-button-box">
      <button class="button load-button button-secondary">Load more</button>
    </div>
  </div>
</section>
