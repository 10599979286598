<section class="recommendation">
  <div class="wrapper">
    <a class="rec-card">
      <div class="image">
        <img src="/assets/images/placeholders/10.webp" corporateImageLazyLoad />
        <span>Bucket list</span>
        <button class="icon icon-heart-empty-white"></button>
      </div>
      <div class="text-content">
        <div class="title">Castle of Füzér</div>
        <div class="lead">
          The Castle of Füzér built in the 13th century sits 552 meters above sea level among the hills of Zemplén Mountains. Its historical
          importance is emphasized by the fact that Péter Perényi, protector of the Crown, brought the Crown of Hungary here after the
          defeat of the Hungarian armies at the battle of Mohács. The castle was renovated by 2016: the outer bailey rebuilt, the chapel,
          the keep and its lower bastion reconstructed.
        </div>
        <div class="more">read more <i class="icon icon-arrow-right-more-red"></i></div>
      </div>
    </a>

    <a class="rec-card">
      <div class="image">
        <img src="/assets/images/placeholders/11.webp" corporateImageLazyLoad />
        <span>Bucket list</span>
        <button class="icon icon-heart-empty-white"></button>
      </div>
      <div class="text-content">
        <div class="title">Castle of Visegrad</div>
        <div class="lead">
          Maybe the most emblematic building of Visegrád which is commonly just referred as the castle of Visegrád, and with this
          emphasizing the cultural value of the...
        </div>
        <div class="more">read more <i class="icon icon-arrow-right-more-red"></i></div>
      </div>
    </a>

    <a class="rec-card">
      <div class="image">
        <img src="/assets/images/placeholders/9.webp" corporateImageLazyLoad />
        <span>Bucket list</span>
        <button class="icon icon-heart-empty-white"></button>
      </div>
      <div class="text-content">
        <div class="title">Széchenyi Palace</div>
        <div class="lead">
          The Castle of Füzér built in the 13th century sits 552 meters above sea level among the hills of Zemplén Mountains. Its historical
          importance is emphasized by the fact that Péter Perényi, protector of the Crown...
        </div>
        <div class="more">read more <i class="icon icon-arrow-right-more-red"></i></div>
      </div>
    </a>

    <a class="rec-card">
      <div class="image">
        <img src="/assets/images/placeholders/8.webp" corporateImageLazyLoad />
        <span>Bucket list</span>
        <button class="icon icon-heart-empty-white"></button>
      </div>
      <div class="text-content">
        <div class="title">Castle of Visegrad</div>
        <div class="lead">
          Maybe the most emblematic building of Visegrád which is commonly just referred as the castle of Visegrád, and with this
          emphasizing the cultural value of the...
        </div>
        <div class="more">read more <i class="icon icon-arrow-right-more-red"></i></div>
      </div>
    </a>

    <a class="rec-card">
      <div class="image">
        <img src="/assets/images/placeholders/7.webp" corporateImageLazyLoad />
        <span>Bucket list</span>
        <button class="icon icon-heart-empty-white"></button>
      </div>
      <div class="text-content">
        <div class="title">Castle of Visegrad</div>
        <div class="lead">
          Maybe the most emblematic building of Visegrád which is commonly just referred as the castle of Visegrád, and with this
          emphasizing the cultural value of the...
        </div>
        <div class="more">read more <i class="icon icon-arrow-right-more-red"></i></div>
      </div>
    </a>

    <a class="rec-card">
      <div class="image">
        <img src="/assets/images/placeholders/6.webp" corporateImageLazyLoad />
        <span>Bucket list</span>
        <button class="icon icon-heart-empty-white"></button>
      </div>
      <div class="text-content">
        <div class="title">Castle of Füzér</div>
        <div class="lead">
          The Castle of Füzér built in the 13th century sits 552 meters above sea level among the hills of Zemplén Mountains. Its historical
          importance is emphasized by the fact that Péter Perényi, protector of the Crown, brought the Crown of Hungary here after the
          defeat of the Hungarian armies at the battle of Mohács. The castle was renovated by 2016: the outer bailey rebuilt, the chapel,
          the keep and its lower bastion reconstructed.
        </div>
        <div class="more">read more <i class="icon icon-arrow-right-more-red"></i></div>
      </div>
    </a>
  </div>

  <div class="bottom-buttons">
    <button class="button button-secondary">Load more</button>
  </div>
</section>
